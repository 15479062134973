import { Component, ChangeDetectionStrategy, forwardRef, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, UntypedFormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'app-input-text-field',
    templateUrl: 'input-text-field.component.html',
    styleUrls: ['input-text-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => InputTextFieldComponent),
        multi: true
    }],
    standalone: true,
    imports: [
        NgIf,
        FormsModule,
        ReactiveFormsModule,
        NgClass,
    ],
})
export class InputTextFieldComponent implements ControlValueAccessor, OnInit {
    @Input() public label: string;
    @Input() public inputType = 'text';
    @Input() public placeholder = '';
    @Input() public submitted = false;
    @Input() public parentControl?: any;
    @Input() public autocomplete = 'on';

    public inputCtrl = new UntypedFormControl('', Validators.pattern(/^[^<>:^]+$/));
    public inputId: string;
    public onChangeCallback: any;
    public onTouchedCallback: void;

    public errorsMessages: any = {
        required: 'Ce champ est obligatoire.',
        pattern: 'Ce champ ne doit contenir que des nombres',
        email: 'Ce champ doit contenir une adresse email valide.',
        samePassword: 'Ce mot de passe est différent que celui entré dans le champs "Nouveau mot de passe".'
    };

    constructor(private readonly changeDetectorRef: ChangeDetectorRef) {
    }

    public ngOnInit(): void {
        this.inputId = this.uniqueIdGenerator();
        this.inputCtrl.valueChanges.subscribe((inputValue: string) => {
            if (null != this.onChangeCallback) {
                this.onChangeCallback(inputValue);
            }
        });
    }

    public writeValue(obj: any): void {
        if (obj === null || obj === undefined) {
            obj = '';
        }

        this.inputCtrl.setValue(obj);
        this.changeDetectorRef.markForCheck();
    }

    public registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

    public getFirstErrorMessage(objects: any): string {
        return this.errorsMessages[Object.keys(objects)[0]];
    }

    private uniqueIdGenerator(): string {
        return `input-text-field-${Date.now()}-${Math.floor(Math.random() * 1000000)}`;
    }
}
