<div class="field">
    <div class="input-number" [class.input-number__disabled]="disabledCtrl">
        <div class="input-number__button" (click)="changeInputValue(false)">
            <button type="button">-</button>
        </div>
        <div class="input-number__value">{{ inputValue }}</div>
        <div class="input-number__button">
            <button type="button" (click)="changeInputValue(true)">+</button>
        </div>
    </div>
</div>
