import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgFor, NgIf } from '@angular/common';

import { IBreadCrumb } from '../../../core/interfaces';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
    standalone: true,
    imports: [
        RouterLink,
        NgFor,
        NgIf,
    ]
})
export class BreadcrumbComponent {
    @Input() public breadCrumbItems: IBreadCrumb[];
}
