import { Component, ChangeDetectionStrategy, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { NgFor, NgIf } from '@angular/common';
import { MatOption } from '@angular/material/core';

@Component({
    selector: 'app-select-field',
    templateUrl: 'select-field.component.html',
    styleUrls: ['select-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => SelectFieldComponent),
        multi: true
    }],
    standalone: true,
    imports: [
        MatFormField,
        MatLabel,
        MatSelect,
        FormsModule,
        ReactiveFormsModule,
        NgFor,
        MatOption,
        NgIf,
    ],
})
export class SelectFieldComponent implements ControlValueAccessor {
    @Input() public label: string;
    @Input() public options: { label: string; value: any }[];
    @Input() public submitted = false;
    @Input() public parentControl?: any;

    @Output() public selectLabelChange: EventEmitter<string> = new EventEmitter();

    public selectCtrl = new UntypedFormControl('');
    public onChangeCallback: any;
    public onTouchedCallback: void;

    public errorsMessages: any = {
        required: 'Ce champ est obligatoire.',
    };

    public writeValue(obj: any): void {
        if (obj === null || obj === undefined) {
            obj = '';
        }

        this.selectCtrl.setValue(obj);
    }

    public selectedValueChange(event: any): void {
        if (null != this.onChangeCallback) {
            this.onChangeCallback(event.value);
        }

        this.selectLabelChange.emit(event.source.triggerValue);
    }

    public registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

    public getFirstErrorMessage(objects: any): string {
        return this.errorsMessages[Object.keys(objects)[0]];
    }
}
