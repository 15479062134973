<div class="field" [class.field-error]="(inputCtrl.dirty || submitted) && (inputCtrl?.errors || parentControl?.errors)">
    <textarea *ngIf="inputType === 'textarea'" [id]="inputId" class="field__input" [formControl]="inputCtrl" [placeholder]="placeholder" [attr.autocomplete]="autocomplete"></textarea>
    <input *ngIf="inputType !== 'textarea'" [id]="inputId" class="field__input" [type]="inputType" [formControl]="inputCtrl" [placeholder]="placeholder" [attr.autocomplete]="autocomplete">
    <div class="field__label"
         [ngClass]="{
            'field__label-small':  inputCtrl.value,
            'field__label-textarea': inputType === 'textarea'
         }">
            <label [for]="inputId" >{{ label }}</label>
    </div>
</div>
<div *ngIf="(inputCtrl.dirty || submitted) && (inputCtrl?.errors || parentControl?.errors)" class="form-field__error-message">
    <span *ngIf="parentControl?.errors">{{ getFirstErrorMessage(parentControl?.errors) }}</span>
    <span *ngIf="inputCtrl?.errors">Ce champ ne doit pas contenir les caratecteres suivants : <, >, :, ^</span>
</div>
