import { Component, ChangeDetectionStrategy, forwardRef, Input, ChangeDetectorRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
    selector: 'app-input-number-field',
    templateUrl: 'input-number-field.component.html',
    styleUrls: ['input-number-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputNumberFieldComponent),
            multi: true
        }
    ],
    standalone: true,
})
export class InputNumberFieldComponent implements ControlValueAccessor {
    @Input() public min?: number;
    @Input() public max?: number;
    @Input() public disabledCtrl = false;
    public inputValue = 0;
    public onChangeCallback: any;
    public onTouchedCallback: void;

    constructor(private readonly changeDetectorRef: ChangeDetectorRef) {
    }

    public writeValue(obj: number): void {
        if (obj === null || obj === undefined) {
            obj = 0;
        }

        this.inputValue = obj;
    }

    public registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

    public changeInputValue(increase: boolean): void {
        if (this.disabledCtrl) {
            return;
        }

        const newInputValue: number = increase ? Number(this.inputValue) + 1 : Number(this.inputValue) - 1;

        if (this.max && newInputValue > this.max) {
            this.inputValue = Number(this.max);
            this.triggerChange();
            return;
        }

        if (this.min && newInputValue < this.min) {
            this.inputValue = Number(this.min);
            this.triggerChange();
            return;
        }

        if ( newInputValue < 1) {
            this.inputValue = 1;
            this.triggerChange();
            return;
        }

        this.inputValue = Number(newInputValue);
        this.triggerChange();
    }

    private triggerChange(): void {
        this.changeDetectorRef.markForCheck();
        if (null != this.onChangeCallback) {
            this.onChangeCallback(this.inputValue);
        }
    }
}
