<nav aria-label="Fil d'ariane">
    <ol class="breadcrumb" itemscope="" itemtype="https://schema.org/BreadcrumbList">
        <li class="breadcrumb__item" itemscope="" itemprop="itemListElement" itemtype="https://schema.org/ListItem">
            <a class="breadcrumb-link" itemtype="https://schema.org/Thing" itemprop="item" routerLink="/">
                <span class="breadcrumb-text" itemprop="name">Le Kiosque</span>
            </a>
            <meta itemprop="position" content="1">
        </li>

        <li *ngFor="let item of breadCrumbItems" class="breadcrumb__item"
            itemscope="" itemprop="itemListElement" itemtype="https://schema.org/ListItem">

            <a
                class="breadcrumb-link"
                itemtype="https://schema.org/Thing"
                itemprop="item" routerLink="{{item.slug}}"
                *ngIf="item.slug !== ''; else titleOnly">
                <span class="breadcrumb-text" itemprop="name">{{item.title}}</span>
            </a>

            <ng-template #titleOnly>
                <span class="breadcrumb-text" itemprop="name">{{item.title}}</span>
            </ng-template>


            <meta itemprop="position" content="{{item.position}}">
        </li>
    </ol>
</nav>
